import { Button, Container, Divider, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, List, ListItem, ListItemButton, ListItemText, ListSubheader, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Header from '../../layouts/Header'
import Footer from '../../layouts/Footer'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { hostname } from '../../hostname'
import ConfirmDialog from '../../components/ConfirmDialog'
import AlertDialog from '../../components/AlertDialog'
import { Box } from '@mui/system'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import Snacks from '../../components/Snacks'

function Account() {
    const navigate = useNavigate()
    const [title, setTitle] = useState('Personal information')
    const [rowData, setRowData] = useState({})
    const [snack, setSnack] = useState({ open: false, msg: "", variant: "success" });
    const [confirmDialog, setConfirmDialog] = useState({ open: false, msg: '' })
    const [alertDialog, setAlertDialog] = useState({ open: false, msg: '' })
    const [showPassword, setShowPassword] = useState({ memberOldPassword: false, memberNewPassword: false, memberNewPassword_confirm: false })

    const handleChange = (e) => {
        setRowData({ ...rowData, [e.target.name]: e.target.value })
    }
    const logout = () => {
        localStorage.clear()
        navigate('/')
    }
    const getMember = async () => {
        try {
            let res = await axios({
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'application/json' },
                method: 'post',
                url: `${hostname}/member/getMember`,
                data: {
                    memberId: localStorage.getItem('memberId')
                }
            })
            if (res.data.status_code === '200' && res.data.message === "get member info success") {
                setRowData(res.data.member)
            } else {
                setSnack({ ...snack, 'open': true, msg: res.data.message, variant: 'error' })
            }
        } catch (error) {
            console.log(error)
            setSnack({ ...snack, 'open': true, msg: error })
        }
    }
    const editMember = async () => {
        try {
            let res = await axios({
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'application/json' },
                method: 'post',
                url: `${hostname}/member/editMember`,
                data: {
                    "memberId": localStorage.getItem('memberId'),
                    "memberEmail": rowData.memberEmail,
                    "memberFirstName": rowData.memberFirstName,
                    "memberLastName": rowData.memberLastName,
                    "memberMobile": rowData.memberMobile
                }
            })
            if (res.data.status_code === '200' && res.data.message === "edit member info success") {
                setSnack({ ...snack, 'open': true, msg: 'update info success', variant: 'success' })
                getMember()
                setTitle('Personal information')
            } else if (res.data.status_code === '206') {
                setSnack({ ...snack, 'open': true, msg: 'phone number is already used', variant: 'error' })
            } else {
                setSnack({ ...snack, 'open': true, msg: res.data.message, variant: 'error' })
            }
        } catch (error) {
            console.log(error)
            setSnack({ ...snack, 'open': true, msg: error })
        }
    }
    const createMemberAddress = async () => {
        try {
            let res = await axios({
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'application/json' },
                method: 'post',
                url: `${hostname}/member/createMemberAddress`,
                data: {
                    "memberId": localStorage.getItem('memberId'),
                    "memberAddress": rowData.memberAddress,
                }
            })
            if (res.data.status_code === '200' && res.data.message === "add member address success") {
                setSnack({ ...snack, 'open': true, msg: 'update address success', variant: 'success' })
                getMember()
                setTitle('Personal information')
            } else {
                setSnack({ ...snack, 'open': true, msg: res.data.message, variant: 'error' })
            }
        } catch (error) {
            console.log(error)
            setSnack({ ...snack, 'open': true, msg: error })
        }
    }
    const memberChangePassword = async () => {
        try {
            if (rowData.memberNewPassword !== rowData.memberNewPassword_confirm) {
                setSnack({ open: true, variant: 'warning', msg: 'password not matched' })
            } else {
                let res = await axios({
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'application/json' },
                    method: 'post',
                    url: `${hostname}/member/memberChangePassword`,
                    data: {
                        memberId: localStorage.getItem('memberId'),
                        memberOldPassword: rowData.memberOldPassword,
                        memberNewPassword: rowData.memberNewPassword
                    }
                })
                if (res.data.status_code === '200' && res.data.message === "change password success") {
                    setSnack({ ...snack, 'open': true, msg: 'Change password success please login', variant: 'success' })
                    logout()
                } else if (res.data.status_code === '207' && res.data.message === "old password incorrect") {
                    setSnack({ ...snack, 'open': true, msg: 'current password incorrect', variant: 'error' })
                } else {
                    setSnack({ ...snack, 'open': true, msg: res.data.message, variant: 'error' })
                }
            }
        } catch (error) {
            console.log(error)
            setSnack({ ...snack, 'open': true, msg: error })
        }
    }
    const deleteMember = async () => {
        try {
            let res = await axios({
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'application/json' },
                method: 'post',
                url: `${hostname}/member/deleteMember`,
                data: {
                    memberId: localStorage.getItem('memberId')
                }
            })
            if (res.data.status_code === '200' && res.data.message === "delete member success") {
                setAlertDialog({ open: true, msg: 'Cancel membership success' })
            } else {
                setSnack({ ...snack, 'open': true, msg: res.data.message, variant: 'error' })
            }
        } catch (error) {
            console.log(error)
            setSnack({ ...snack, 'open': true, msg: error })
        }
    }

    useEffect(() => {
        getMember()
        window.scrollTo(0, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (title === 'Cancel membership') {
            setConfirmDialog({ open: true, msg: 'Are you sure you want to cancel your membership ?' })
        } else if (title === 'Logout') {
            logout()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title])


    return (
        <>
            <Header />
            <Container maxWidth='lg' >
                <Grid container spacing={2} mt={2} justifyContent='flex-end'>
                    <Grid item xs={12}>
                        <Box sx={{ display: { xs: 'none', lg: 'flex', flexDirection: 'column' } }}>
                            <Typography variant='h3'>{title}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <Box sx={{ display: { xs: 'flex', lg: 'none' } }}>
                            <FormControl sx={{ minWidth: '100%' }}>
                                <InputLabel htmlFor="grouped-select">Menu</InputLabel>
                                <Select defaultValue="" id="grouped-select" label="Menu"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                >
                                    <ListSubheader>membership</ListSubheader>
                                    <MenuItem value={'Personal information'}>Personal information</MenuItem>
                                    {/* <MenuItem value={'Order History'}>Order History</MenuItem> */}
                                    {/* <MenuItem value={'My order'}>My order</MenuItem> */}
                                    <ListSubheader>Account settings</ListSubheader>
                                    <MenuItem value={'Edit personal information'}>Edit personal information</MenuItem>
                                    <MenuItem value={'Edit address'}>Edit address</MenuItem>
                                    <MenuItem value={'Change password'}>Change password</MenuItem>
                                    <MenuItem value={'Cancel membership'}>Cancel membership</MenuItem>
                                    <MenuItem value={'Logout'}>Logout</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: { xs: 'none', lg: 'flex', flexDirection: 'column' } }}>
                            <Divider sx={{ mb: 1 }} />
                            <Typography fontWeight='bold'>membership</Typography>
                            <List>
                                <ListItem disablePadding key={'Personal information'}>
                                    <ListItemButton onClick={() => setTitle('Personal information')}>
                                        <ListItemText primary="Personal information" />
                                    </ListItemButton>
                                </ListItem>
                                {/* <ListItem disablePadding key={'Order History'}>
                                    <ListItemButton onClick={() => setTitle('Order History')}>
                                        <ListItemText primary="Order History" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding key={'My order'}>
                                    <ListItemButton onClick={() => setTitle('My order')}>
                                        <ListItemText primary="My order" />
                                    </ListItemButton>
                                </ListItem> */}
                            </List>
                            <Divider sx={{ mb: 1 }} />
                            <Typography fontWeight='bold'>Account settings</Typography>
                            <List>
                                <ListItem disablePadding key={'Edit personal information'}>
                                    <ListItemButton onClick={() => setTitle('Edit personal information')}>
                                        <ListItemText primary="Edit personal information" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding key={'Edit address'}>
                                    <ListItemButton onClick={() => setTitle('Edit address')}>
                                        <ListItemText primary="Edit address" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding key={'Change password'}>
                                    <ListItemButton onClick={() => setTitle('Change password')}>
                                        <ListItemText primary="Change password" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding key={'Cancel membership'}>
                                    <ListItemButton onClick={() => setTitle('Cancel membership')}>
                                        <ListItemText primary="Cancel membership" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding key={'Logout'}>
                                    <ListItemButton onClick={() => setTitle('Logout')}>
                                        <ListItemText primary="Logout" />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={10}>
                        <Box sx={{ border: 'solid 1px #dedede', py: 4, px: 4 }}>
                            {title === 'Personal information' &&
                                <Grid container spacing={3} justifyContent='center'>
                                    <Grid item xs={12}>
                                        <Typography fontWeight='bold'>Email</Typography>
                                        <Typography>{rowData.memberEmail || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontWeight='bold'>First name surname</Typography>
                                        <Typography>{`${rowData.memberFirstName || '-'} ${rowData.memberLastName}`}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontWeight='bold'>Phone number</Typography>
                                        <Typography>{rowData.memberMobile || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontWeight='bold'>Address</Typography>
                                        <Typography>{rowData.memberAddress || '-'}</Typography>
                                    </Grid>
                                </Grid>
                            }
                            {title === 'Order History' &&
                                <Grid container spacing={3} justifyContent='center'>
                                    <Grid item xs={12}>
                                        <Typography >You don't have any completed orders yet.</Typography>
                                    </Grid>
                                </Grid>
                            }
                            {title === 'My order' &&
                                <Grid container spacing={3} justifyContent='center'>
                                    <Grid item xs={12}>
                                        <Typography >You don't have any orders yet.</Typography>
                                    </Grid>
                                </Grid>
                            }
                            {title === 'Edit personal information' &&
                                <Grid container spacing={3} justifyContent='center'>
                                    <Grid item xs={12} lg={7}>
                                        <Typography fontWeight='bold' mb={0.5}>First name</Typography>
                                        <TextField
                                            fullWidth
                                            variant='standard'
                                            size='small'
                                            type='text'
                                            placeholder='first name'
                                            name='memberFirstName'
                                            value={rowData.memberFirstName || ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <Typography fontWeight='bold' mb={0.5}>Surname</Typography>
                                        <TextField
                                            fullWidth
                                            variant='standard'
                                            size='small'
                                            type='text'
                                            placeholder='last name'
                                            name='memberLastName'
                                            value={rowData.memberLastName || ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <Typography fontWeight='bold' mb={0.5}>Phone number</Typography>
                                        <TextField
                                            fullWidth
                                            variant='standard'
                                            size='small'
                                            type='number'
                                            placeholder='mobile'
                                            name='memberMobile'
                                            value={rowData.memberMobile || ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <Button variant='contained' fullWidth onClick={() => editMember()}>ok</Button>
                                    </Grid>
                                </Grid>
                            }
                            {title === 'Edit address' &&
                                <Grid container spacing={3} justifyContent='center'>
                                    <Grid item xs={12} lg={7}>
                                        <Typography fontWeight='bold' mb={0.5}>Address</Typography>
                                        <TextField
                                            fullWidth
                                            variant='standard'
                                            size='small'
                                            type='text'
                                            placeholder='address'
                                            name='memberAddress'
                                            value={rowData.memberAddress || ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <Button variant='contained' fullWidth onClick={() => createMemberAddress()}>ok</Button>
                                    </Grid>
                                </Grid>
                            }
                            {title === 'Change password' &&
                                <Grid container spacing={3} justifyContent='center'>
                                    <Grid item xs={12} lg={7}>
                                        <Typography fontWeight='bold' mb={0.5}>Current password</Typography>
                                        <FormControl sx={{ width: '100%' }} variant="standard">
                                            <Input
                                                name='memberOldPassword'
                                                value={rowData.memberOldPassword || ''}
                                                onChange={handleChange}
                                                type={showPassword.memberOldPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={(e) => setShowPassword({ ...showPassword, memberOldPassword: !showPassword.memberOldPassword })}
                                                            onMouseDown={(event) => event.preventDefault()}
                                                        >
                                                            {showPassword.memberOldPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <Typography fontWeight='bold' mb={0.5}>New password</Typography>
                                        <FormControl sx={{ width: '100%' }} variant="standard">
                                            <Input
                                                name='memberNewPassword'
                                                value={rowData.memberNewPassword || ''}
                                                onChange={handleChange}
                                                type={showPassword.memberNewPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={(e) => setShowPassword({ ...showPassword, memberNewPassword: !showPassword.memberNewPassword })}
                                                            onMouseDown={(event) => event.preventDefault()}
                                                        >
                                                            {showPassword.memberNewPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <Typography fontWeight='bold' mb={0.5}>Confirm new password</Typography>
                                        <FormControl sx={{ width: '100%' }} variant="standard">
                                            <Input
                                                name='memberNewPassword_confirm'
                                                value={rowData.memberNewPassword_confirm || ''}
                                                onChange={handleChange}
                                                type={showPassword.memberNewPassword_confirm ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={(e) => setShowPassword({ ...showPassword, memberNewPassword_confirm: !showPassword.memberNewPassword_confirm })}
                                                            onMouseDown={(event) => event.preventDefault()}
                                                        >
                                                            {showPassword.memberNewPassword_confirm ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Button variant='contained' fullWidth onClick={() => memberChangePassword()}>ok</Button>
                                    </Grid>
                                </Grid>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Snacks
                    open={snack.open}
                    onClose={bool => setSnack({ ...snack, 'open': bool })}
                    msg={snack.msg}
                    variant={snack.variant}
                />
                <ConfirmDialog
                    open={confirmDialog.open}
                    msg={confirmDialog.msg}
                    buttonName={'cancel'}
                    onClose={(bool) => {
                        if (bool) {
                            setConfirmDialog({ ...confirmDialog, open: false })
                            deleteMember()
                        } else {
                            setConfirmDialog({ ...confirmDialog, open: false })
                        }
                    }}
                />
                <AlertDialog
                    open={alertDialog.open}
                    msg={alertDialog.msg}
                    onClose={(bool) => {
                        setAlertDialog({ ...alertDialog, open: bool })
                        navigate('/')
                    }}
                />
            </Container>
            <Footer />
        </>
    )
}

export default Account