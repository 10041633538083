import * as React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { lazy } from "react";
import Loadable from "./components/Loadable";
import Login from "./views/auth/Login";
import Account from "./views/auth/Account";
import Register from "./views/auth/Register";
import axios from "axios";
import Loading from "./components/Loading";
import ShippingStatus from "./views/ShippingStatus";
import DeliveryInformation from "./views/footer_content/DeliveryInformation";
import PrivacyTerms from "./views/footer_content/PrivacyTerms";
import WarrantyInfo from "./views/footer_content/WarrantyInfo";
import WorkShop from "./views/footer_content/WorkShop";
const Home = Loadable(lazy(() => import("./views/Home")));
const NotFound = Loadable(lazy(() => import("./views/NotFound")));
const Success = Loadable(lazy(() => import("./views/Success")));

const Tray = Loadable(lazy(() => import("./views/Tray")));
const Canopies = Loadable(lazy(() => import("./views/Canopies")));
const Performance = Loadable(lazy(() => import("./views/Performance")));
const Store = Loadable(lazy(() => import("./views/Store")));
const About = Loadable(lazy(() => import("./views/About")));
const Faq = Loadable(lazy(() => import("./views/Faq")));
const ReturnPolicy = Loadable(lazy(() => import("./views/footer_content/ReturnPolicy")));
const Customize = Loadable(lazy(() => import("./views/Customize")));
const ShoppingCart = Loadable(lazy(() => import("./views/ShoppingCart")));
const Purchase = Loadable(lazy(() => import("./views/Purchase")));
const PaymentCompletion = Loadable(
  lazy(() => import("./views/payment/Completion"))
);

function App() {
  const [loading, setLoading] = React.useState(false);
  const isPageLogin = new URL(window.location.href).pathname === `/login`;

  axios.interceptors.request.use(
    function (config) {
      setLoading(true);
      return config;
    },
    function (error) {
      setLoading(false);
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      setLoading(false);
      return response;
    },
    async function (error) {
      if (!isPageLogin && error.response.status === 401) {
        localStorage.clear();
        window.location.href = `/login`;
      }
      if (
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized"
      ) {
        localStorage.clear();
        window.location.href = `/login`;
      }
      return Promise.reject(error);
    }
  );

  const Private = (Component) => {
    if (localStorage.getItem("token")) {
      return Component;
    } else {
      return <Navigate to="/login" />;
    }
  };

  return (
    <>
      <BrowserRouter>
        <Loading open={loading} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/success" element={<Success />} />
          <Route exact path="/trays" element={<Tray />} />
          <Route exact path="/canopies" element={<Canopies />} />
          <Route exact path="/performance" element={<Performance />} />
          <Route exact path="/store" element={<Store />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/account" element={Private(<Account />)} />
          <Route exact path="/customize" element={<Customize />} />
          <Route exact path="/shopping_cart" element={<ShoppingCart />} />
          <Route exact path="/purchase" element={<Purchase />} />
          <Route exact path="/shipping" element={<ShippingStatus />} />

          <Route exact path="/return_policy" element={<ReturnPolicy />} />
          <Route exact path="/delivery_information" element={<DeliveryInformation />} />
          <Route exact path="/privacy_terms" element={<PrivacyTerms />} />
          <Route exact path="/warranty_info" element={<WarrantyInfo />} />
          <Route exact path="/work_shop" element={<WorkShop />} />
          <Route
            exact
            path="/paymentCompletion"
            element={<PaymentCompletion />}
          />

          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
