import React, { useState, useEffect } from 'react'
import { Box, Button, Container, Dialog, DialogContent, DialogTitle, Divider, Grid, Link, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material'
import tray_1 from '../assets/trays_png_1.webp'
import { styled } from '@mui/material/styles';
import { CloseRounded, CopyAllRounded, Home } from '@mui/icons-material'
import { hostname, tax } from '../hostname'
import axios from 'axios'
import Snacks from '../components/Snacks'
import AlertDialog from '../components/AlertDialog'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import StepConnector, {
    stepConnectorClasses
} from "@mui/material/StepConnector";
import order from '../assets/choices.png'
import confirm from '../assets/confirm.png'
import box from '../assets/box.png'
import tracking from '../assets/tracking.png'

const ShippingStatus = () => {
    const navigate = useNavigate()
    const orderId = new URLSearchParams(window.location.search).get("orderId")
    const [rowData, setRowData] = useState({})
    const [alertDialog, setAlertDialog] = useState({ open: false, msg: '' })
    const [open, setOpen] = useState(false)
    const [snack, setSnack] = useState({ open: false, msg: "", variant: "success" });
    const [activeStep, setActiveStep] = useState(0);

    const copy = (tracking) => {
        navigator.clipboard.writeText(tracking)
        setSnack({ open: true, msg: 'copied tracking', variant: 'success' })
    }
    const checkOrder = async () => {
        try {
            let res = await axios({
                method: 'post',
                url: `${hostname}/order/checkOrder`,
                data: {
                    "orderId": orderId,
                    "buyerMobile": "",
                    "buyerEmail": ""
                }
            })
            if (res.data.status_code === '200' && res.data.message === "get order success") {
                setRowData(res.data.order[0])
                let orderPaymentStatus = 0
                if (parseInt(res.data.order[0]?.orderPaymentStatus) === 3) {
                    orderPaymentStatus = 3
                } else {
                    orderPaymentStatus = parseInt(res.data.order[0]?.orderPaymentStatus) - 1
                }
                setActiveStep(orderPaymentStatus)
            } else {
                setSnack({ ...snack, 'open': true, msg: res.data.message, variant: 'error' })
            }
        } catch (error) {
            console.log(error)
            setSnack({ ...snack, 'open': true, msg: error })
        }
    }

    useEffect(() => {
        checkOrder()
        window.scrollTo(0, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <Container disableGutters maxWidth='xl' >
                {/* <Header /> */}
                <Box sx={{ p: 2, backgroundColor: '#3f51b5', }}>
                    <Grid container>
                        <Grid item xs={1}>
                            <Home sx={{ color: '#fff' }} onClick={() => navigate('/')} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography fontWeight='bold' color='white' align='center'>Track Orders</Typography>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Box>
                <div style={{ backgroundColor: '#fafafa', minHeight: '100vh' }}>
                    <Container maxWidth='lg' sx={{ pt: 3 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Box sx={{ backgroundColor: '#fff', boxShadow: '2px 2px 10px #dedede80', borderRadius: '10px' }}>
                                    <Box sx={{ p: 2 }}>
                                        <Typography fontWeight='bold'>{`Order#: ${orderId}`}</Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ p: 2 }}>
                                        <Stack direction='row' justifyContent='space-between'>
                                            <Typography>{`Buyer name`}</Typography>
                                            <Typography>{`${rowData.buyerName}`}</Typography>
                                        </Stack>
                                        <Stack direction='row' justifyContent='space-between'>
                                            <Typography>{`Order date`}</Typography>
                                            <Typography>{`${moment(rowData.createDate).format('DD MMM YYYY')}`}</Typography>
                                        </Stack>
                                        <Stack direction='row' justifyContent='space-between'>
                                            <Typography>{`Tracking number`}</Typography>
                                            <Stack direction='row' spacing={1}>
                                                <Typography >{`${rowData.trackingNumber || '-'}`}</Typography>
                                                {rowData.trackingNumber &&
                                                    <CopyAllRounded onClick={() => copy(rowData.trackingNumber)} />
                                                }
                                            </Stack>
                                        </Stack>
                                        <Stack direction='row' justifyContent='space-between'>
                                            <Typography>{`Tracking url`}</Typography>
                                            <Stack direction='row' spacing={1}>
                                                <CssButton variant='text' size='small' onClick={() => window.open(rowData.trackingUrl,'_blank')}><Typography fontWeight='bold' color='#3f51b5' align='right' sx={{ whiteSpace: 'nowrap', width: '250px', textOverflow: 'ellipsis', overflow: 'hidden'}}>{`${rowData.trackingUrl || '-'}`}</Typography></CssButton>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ p: 2 }}>
                                        {
                                            rowData.product?.map((item, index) => (
                                                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                    <Stack direction='row' alignItems='center' spacing={0.5}>
                                                        <Box sx={{ p: 0.5 }}>
                                                            <img alt='product_tddesign' src={item.productImage[0] || ''} width='60px' height='auto' />
                                                        </Box>
                                                        <Stack>
                                                            <Typography >{item.productTitle}</Typography>
                                                            <Stack direction='row' spacing={0.5}>
                                                                <Typography fontSize='small' color='GrayText'>{`Color: ${item.productColorName}`}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </Stack>
                                                    <Stack alignItems='end'>
                                                        <Typography >{`$${Intl.NumberFormat('th', { maximumFractionDigits: 2 }).format(item.price)}`}</Typography>
                                                        <Typography fontSize='small' color='GrayText'>{`Qty: ${item.quantity}`}</Typography>
                                                    </Stack>
                                                </Stack>
                                            ))
                                        }
                                        <Stack mt={1}>
                                            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                <Typography>Subtotal</Typography>
                                                <Typography >{`$${Intl.NumberFormat('th', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(rowData.priceAmount - (rowData.priceAmount * tax) / 110)}`}</Typography>
                                            </Stack>
                                            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                <Typography fontSize='small' color='GrayText'>Shipping</Typography>
                                                <Typography fontSize='small' color='GrayText'>{`$${Intl.NumberFormat('th', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(parseFloat(rowData.shippingPrice || 0) * tax) / 110}`}</Typography>
                                            </Stack>
                                            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                <Typography fontSize='small' color='GrayText'>Install service</Typography>
                                                <Typography fontSize='small' color='GrayText'>{`$${Intl.NumberFormat('th', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(parseFloat(rowData.installPrice || 0) * tax) / 110}`}</Typography>
                                            </Stack>
                                            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                <Typography fontSize='small' color='GrayText'>Gst</Typography>
                                                <Typography fontSize='small' color='GrayText'>{`$${Intl.NumberFormat('th', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(((parseFloat(rowData.priceAmount) + parseFloat(rowData.installPrice || 0) + parseFloat(rowData.shippingPrice || 0)) * tax) / 110)}`}</Typography>
                                            </Stack>
                                            <Divider sx={{ borderStyle: 'dashed', my: 1 }} />
                                            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                <Typography>Total</Typography>
                                                <Typography fontWeight='bold'>{`$${Intl.NumberFormat('th', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(parseFloat(rowData.priceAmount || 0) + parseFloat(rowData.shippingPrice || 0) + parseFloat(rowData.installPrice || 0))}`}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ p: 2 }}>
                                        <Typography fontWeight='bold'>{`Track Order`}</Typography>
                                        <Stepper activeStep={activeStep} orientation="vertical" connector={<QontoConnector />}>
                                            <Step key={'Order Placed'}>
                                                <StepLabel StepIconComponent={QontoStepIcon}>
                                                    <Stack direction='row' spacing={1}>
                                                        <Box sx={{ p: 1, width: 30, height: 30, backgroundColor: activeStep >= 0 ? '#3f51b5' : '#bababa', borderRadius: '50%' }}>
                                                            <img alt='Placed' src={order} width='100%' height='auto' />
                                                        </Box>
                                                        <Stack>
                                                            <Typography fontWeight='bold' color={activeStep >= 0 ? '#3f51b5' : '#bababa'}>Order Placed</Typography>
                                                            <Typography variant="caption" color={activeStep >= 0 ? 'gray' : '#bababa'}>{`received your order on ${moment().format('DD MMM YYYY')}`}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </StepLabel>
                                            </Step>
                                            <Step key={'Order Confirmed'}>
                                                <StepLabel StepIconComponent={QontoStepIcon}>
                                                    <Stack direction='row' spacing={1}>
                                                        <Box sx={{ p: 1, width: 30, height: 30, backgroundColor: activeStep >= 1 ? '#3f51b5' : '#bababa', borderRadius: '50%' }}>
                                                            <img alt='Confirmed' src={confirm} width='100%' height='auto' />
                                                        </Box>
                                                        <Stack>
                                                            <Typography fontWeight='bold' color={activeStep >= 1 ? '#3f51b5' : '#bababa'}>Order Confirmed</Typography>
                                                            <Typography variant="caption" color={activeStep >= 1 ? 'gray' : '#bababa'}>{`confirmed on ${moment().format('DD MMM YYYY')}`}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </StepLabel>
                                            </Step>
                                            <Step key={'Order Processed'}>
                                                <StepLabel StepIconComponent={QontoStepIcon}>
                                                    <Stack direction='row' spacing={1}>
                                                        <Box sx={{ p: 1, width: 30, height: 30, backgroundColor: activeStep >= 2 ? '#3f51b5' : '#bababa', borderRadius: '50%' }}>
                                                            <img alt='Processed' src={box} width='100%' height='auto' />
                                                        </Box>
                                                        <Stack>
                                                            <Typography fontWeight='bold' color={activeStep >= 2 ? '#3f51b5' : '#bababa'}>Order Processed</Typography>
                                                            <Typography variant="caption" color={activeStep >= 2 ? 'gray' : '#bababa'}>{`preparing your order`}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </StepLabel>
                                            </Step>
                                            <Step key={'Out for Delivery'}>
                                                <StepLabel StepIconComponent={QontoStepIcon}>
                                                    <Stack direction='row' spacing={1}>
                                                        <Box sx={{ p: 1, width: 30, height: 30, backgroundColor: activeStep >= 3 ? '#3f51b5' : '#bababa', borderRadius: '50%' }}>
                                                            <img alt='Delivery' src={tracking} width='100%' height='auto' />
                                                        </Box>
                                                        <Stack>
                                                            <Typography fontWeight='bold' color={activeStep >= 3 ? '#3f51b5' : '#bababa'}>Out for Delivery</Typography>
                                                            <Typography variant="caption" color={activeStep >= 3 ? 'gray' : '#bababa'}>{`Your order is out for delivery`}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </StepLabel>
                                            </Step>
                                        </Stepper>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Container>

            <Snacks
                open={snack.open}
                onClose={bool => setSnack({ ...snack, 'open': bool })}
                msg={snack.msg}
                variant={snack.variant}
            />
            <AlertDialog
                open={alertDialog.open}
                msg={alertDialog.msg}
                onClose={(bool) => {
                    setAlertDialog({ ...alertDialog, open: bool })
                }}
            />
            <Dialog
                open={open}
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='h3'>Preview</Typography>
                        <CloseRounded onClick={() => setOpen(false)} />
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack direction='row' justifyContent='center'>
                        <img alt='product_tddesign' src={tray_1} width='400px' height='auto' style={{ marginRight: '-200px' }} />
                        <img alt='product_tddesign' src={tray_1} width='400px' height='auto' style={{ marginLeft: '-200px' }} />
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default ShippingStatus

const CssButton = styled(Button)({
    justifyContent: "right",
    textTransform: 'inherit',
    padding: "0px",
    borderRadius: "5px",
  });

  
const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    marginLeft: 7,
    marginRight: 3,
    ...(ownerState.active && {
        color: "#3f51b5"
    }),
    "& .QontoStepIcon-completedIcon": {
        width: 12,
        height: 12,
        borderRadius: "50%",
        backgroundColor: "#3f51b5"
    },
    "& .QontoStepIcon-circle": {
        width: 12,
        height: 12,
        borderRadius: "50%",
        backgroundColor: "currentColor"
    }
}));
function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <div className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}
const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)"
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderLeft: 'solid 2px #3f51b5',
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderLeft: 'solid 2px #3f51b5',
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderLeft: 'solid 2px ',
        borderColor:
            theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
        borderTopWidth: 10,

    }
}));