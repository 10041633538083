import React from 'react'
import Header from '../../layouts/Header'
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import Footer from '../../layouts/Footer'
function DeliveryInformation() {
    return (
        <>
            <Header />
            <Box sx={{
                py: 5
            }}>
                <Container maxWidth='lg' >
                    <Grid container mt={2} alignItems='center'>
                        <Grid item xs={12}>
                            <Typography variant='h2'>Delivery information</Typography>
                            <Stack spacing={1} mt={2}>
                                <Typography>• All orders over 20kg will be delivered by starTrack Express and require a signature at point of delivery it is preferred to provide a bussiness address or an address where someone will be home for order over 20kg. Redelivery charges mag apply if there is no one available to accept delivery</Typography>
                                <Typography>• All product under 20kg. will be delivered by Australia Post, in 3 - 5 business days</Typography>
                                <Typography>• All orders are dispatched from Gloucester NSW</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Footer />
        </>
    )
}

export default DeliveryInformation