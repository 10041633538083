import React, { createContext, useReducer } from "react";

const tdState = {
    productItems: []
}

const tdContext = createContext(tdState)

const TDContextProvider = ({ children }) => {
    const [state, tdDispatch] = useReducer(tdReducer, tdState)

    const setProduct = (payload) => {
        tdDispatch({ type: "PRODUCT_SET", payload })
    }
    const increaseProduct = (payload) => {
        tdDispatch({ type: "PRODUCT_INCREASE_ITEM", payload })
    }
    const decreaseProduct = (payload) => {
        tdDispatch({ type: "PRODUCT_DECREASE_ITEM", payload })
    }
    const removeProduct = (payload) => {
        tdDispatch({ type: "PRODUCT_REMOVE_ITEM", payload })
    }
    const clearProduct = (payload) => {
        tdDispatch({ type: "PRODUCT_CLEAR", payload })
    }
    
    const func = {
        setProduct,
        increaseProduct,
        decreaseProduct,
        removeProduct,
        clearProduct,
        ...state
    }

    return (
        <tdContext.Provider value={func}>
            {children}
        </tdContext.Provider>
    )
}

export { tdContext, TDContextProvider }

export const sumProductItems = productItems => {
    let priceAmount = productItems.reduce((total, product) => total + (product.price * product.quantity), 0).toFixed(2);
    let quantityAmount = productItems.reduce((total, product) => total + product.quantity, 0);
    return { priceAmount, quantityAmount }
}

const tdReducer = (state, action) => {
    switch (action.type) {
        case "PRODUCT_SET":
            let newProduct = {
                "productId": action.payload.productId,
                "productTypeId": action.payload.productTypeId,
                "productReview": action.payload.productReview,
                "productTitle": action.payload.productTitle,
                "productColor": action.payload.color.colorId,
                "productColorName": action.payload.color.colorName,
                "quantity": 1,
                "price": action.payload.productPrice || 0,
                "productImage": action.payload.productImage || []
            }
            let oldProduct = state.productItems.find((item => ((item.productId === action.payload.productId) && (item.productColor === action.payload.color.colorId))))
            if (oldProduct) {
                oldProduct.quantity += 1
            } else {
                state.productItems.push(newProduct)
            }
            return {
                ...state,
                ...sumProductItems(state.productItems),
                productItems: [...state.productItems]
            }
        case "PRODUCT_INCREASE_ITEM":
            state.productItems[state.productItems.findIndex(item => (item.productId === action.payload.productId))].quantity++
            return {
                ...state,
                ...sumProductItems(state.productItems),
                productItems: [...state.productItems],
            }
        case "PRODUCT_DECREASE_ITEM":
            state.productItems[state.productItems.findIndex(item => (item.productId === action.payload.productId))].quantity--
            return {
                ...state,
                ...sumProductItems(state.productItems),
                productItems: [...state.productItems],
            }
        case "PRODUCT_REMOVE_ITEM":
            return {
                ...state,
                ...sumProductItems(state.productItems.filter(item => item.productId !== action.payload.productId)),
                productItems: [...state.productItems.filter(item => item.productId !== action.payload.productId)]
            }
        case "PRODUCT_CLEAR":
            state.productItems = []
            return {
                ...state,
                ...sumProductItems(state.productItems),
                productItems: [...state.productItems]
            }

        default:
            return state
    }
}
