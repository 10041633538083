import { WarningOutlined } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'

function AlertDialog(props) {
    const { open, msg } = props
    return (
        <>
            <Dialog
                open={open}
                maxWidth='xs'
                style={{ borderRadius: '50px' }}
            >
                <DialogContent dividers>
                    <Stack alignItems='center' px={2} py={1} spacing={1}>
                        <WarningOutlined sx={{ fontSize: '40px' }} />
                        <Typography align='center'>{msg}</Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={1} justifyContent='center'>
                        <Grid item xs={12}>
                            <Button fullWidth color='primary' variant='contained' onClick={() => props.onClose(false)}>ตกลง</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AlertDialog