import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loading(props) {
  return (
    <Backdrop sx={{
      zIndex: 1299,
    }}
      open={props.open}>
      <div sx={{
        position: "absolute"
      }}>
      </div>
      <CircularProgress
        size={78}
        thickness={5.0}
        sx={{
          color: '#3f51b5',
          position: "absolute",
          zIndex: 1300
        }}
      />
    </Backdrop>
  );
}
