import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Button, Container, Grid, IconButton, InputAdornment, Link, OutlinedInput, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { hostname } from '../../hostname'
import Snacks from '../../components/Snacks'
import { useNavigate } from 'react-router-dom'
function Login() {
    const navigate = useNavigate()
    const [rowData, setRowData] = useState({})
    const [showPassword, setShowPassword] = useState(false)
    const [snack, setSnack] = useState({ open: false, msg: "", variant: "success" });

    const handleChange = (e) => {
        setRowData({ ...rowData, [e.target.name]: e.target.value })
    }
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const login = async () => {
        try {
            let res = await axios({
                method: 'post',
                url: `${hostname}/auth/loginMember`,
                data: rowData
            })

            if (res.data.status_code === '200' && res.data.message === "password correct") {
                localStorage.setItem('token', res.data.authen)
                localStorage.setItem('memberId', res.data.user_profile.memberId)
                navigate('/account')
            } else {
                setSnack({ ...snack, 'open': true, msg: res.data.message, variant: 'error' })
            }
        } catch (error) {
            console.log(error)
            setSnack({ ...snack, 'open': true, msg: error })
        }
    }
    useEffect(() => {
        if (localStorage.getItem('token')) {
            navigate('/account')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>

            <div
                style={{
                    height: '100vh',
                    backgroundImage: 'linear-gradient(to bottom left, #1A194D, #18294E)'
                }}
            >
                <Box sx={{ position: 'fixed', right: 0, mt: 5, mr: 10, width: '100px', height: '100px', borderRadius: '50px', backgroundColor: '#ffffff50' }} />
                <Box sx={{ position: 'fixed', right: 0, bottom: 0, mb: 14, mr: 10, width: '120px', height: '120px', borderRadius: '60px', backgroundColor: '#ffffff37' }} />
                <Box sx={{ position: 'fixed', right: 0, bottom: 0, mb: 34, mr: 45, width: '80px', height: '80px', borderRadius: '40px', backgroundColor: '#ffffff37' }} />
                <Box sx={{ position: 'fixed', right: 0, mt: 39, mr: 20, width: '100px', height: '100px', borderRadius: '50px', backgroundColor: '#ffffff50' }} />
                <Box sx={{ position: 'fixed', mt: 15, ml: 19, width: '100px', height: '100px', borderRadius: '50px', backgroundColor: '#ffffff30' }} />
                <Box sx={{ position: 'fixed', right: 0, mt: 7, mr: 8, width: '100px', height: '100px', borderRadius: '50px', backgroundColor: '#ffffff50' }} />
                <Box sx={{ position: 'fixed', mt: 45, ml: 15, width: '150px', height: '150px', borderRadius: '100px', backgroundColor: '#ffffff45' }} />
                <Box sx={{ position: 'fixed', mt: 77, ml: 45, width: '100px', height: '100px', borderRadius: '50px', backgroundColor: '#ffffff20' }} />
                <div style={{
                    margin: 0,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: '100%',
                    msTransform: 'translate(-50%, -50%)',
                    transform: 'translate(-50%, -50%)',
                }}>
                    <Box>
                        <Grid container justifyContent='center'>
                            {/* <Grid item xs={11} sm={6} lg={5} p={2} sx={{ backgroundColor: '#fff', boxShadow: ' 2px 2px 10px' }}>
                        <Typography color='GrayText' align='center'>Sign in with</Typography>
                        </Grid> */}
                            <Grid item xs={11} sm={6} lg={5} p={2} sx={{ backgroundColor: '#fff', boxShadow: ' 2px 2px 10px' }}>
                                <div style={{
                                    minHeight: '70vh',
                                    display: 'flex',
                                    flexDirection: 'column'

                                }}>
                                    <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                                        <Typography color='GrayText'>Don't you have an account?</Typography>
                                        <Button variant='outlined' size='small' sx={{ fontSize: 12 }} onClick={() => navigate('/register')}>SIGN UP</Button>
                                    </Stack>
                                    <div style={{
                                        margin: 0,
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        width: '100%',
                                        msTransform: 'translate(-50%, -50%)',
                                        transform: 'translate(-50%, -50%)',
                                    }}>
                                        <Container maxWidth='xs'>
                                            <Stack p={2}>
                                                <Typography fontWeight='bold' fontSize={30} mt={5}>Welcome Back</Typography>
                                                <Typography mb={3} color='GrayText'>Login your account</Typography>
                                                <Stack spacing={2}>
                                                    <Stack spacing={0.5}>
                                                        <Typography >Username</Typography>
                                                        <OutlinedInput
                                                            size='small'
                                                            type='text'
                                                            placeholder='your email'
                                                            name='memberEmail'
                                                            value={rowData.memberEmail || ''}
                                                            onChange={handleChange}
                                                        />
                                                    </Stack>
                                                    <Stack spacing={0.5}>
                                                        <Typography >Password</Typography>
                                                        <OutlinedInput
                                                            size='small'
                                                            type={showPassword ? 'text' : 'password'}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            placeholder='your password'
                                                            name='memberPassword'
                                                            value={rowData.memberPassword || ''}
                                                            onChange={handleChange}
                                                        />
                                                    </Stack>
                                                    <Stack direction='row' justifyContent='flex-end'>
                                                        <Link><Typography color='GrayText'>Forget password ?</Typography></Link>
                                                    </Stack>
                                                    <Button fullWidth variant='contained' color='secondary' onClick={() => login()}>Login</Button>
                                                </Stack>
                                            </Stack>
                                        </Container>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
            <Snacks
                open={snack.open}
                onClose={bool => setSnack({ ...snack, 'open': bool })}
                msg={snack.msg}
                variant={snack.variant}
            />
        </>
    )
}

export default Login