import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'

function ConfirmDialog(props) {
    const { open, msg, buttonName } = props
    return (
        <>
            <Dialog
                open={open}
                maxWidth='xs'
                style={{ borderRadius: '50px' }}
            >
                <DialogContent dividers>
                    <Stack alignItems='center' px={2} py={1}>
                        <Typography align='center'>{msg}</Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={1} justifyContent='center'>
                        <Grid item xs={6}>
                            <Button fullWidth color='primary' variant='contained' onClick={() => props.onClose(true)}>ok</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth color='primary' variant='contained' onClick={() => props.onClose(false)}>{buttonName || 'cancel'}</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConfirmDialog