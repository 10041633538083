import React, { createContext } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { colors } from "@mui/material";

const ThemeContext = createContext({});

const ThemeProviderContext = ({ children }) => {

  const Themes = createTheme({
    palette: {
      background: {
        default: '#ffffff',
        paper: colors.common.white
      },
      primary: {
        main: "#1e1e1e",
        contrastText: '#ffffff'
      },
      secondary: {
        main: "#3f51b5",
        contrastText: '#ffffff'
      },
      neutral: {
        main: "#ffffff"
      },
      success: {
        main: '#2e7d32',
        contrastText: '#ffffff'
      },
      warning: {
        main: '#ffecb3',
      }
    },
    typography: {
      fontFamily: 'line_seed_sans_th, Mitr, sans-serif',
      color: '#1e1e1e',
      fontSize:14,
      // fontWeightMedium: 400,
      // fontWeightRegular: 400,
      // fontWeightBold: 500
      h6: {
        fontWeight: 500,
        color: '#1e1e1e',
        fontSize: '0.85rem'
      },
      h5: {
        fontSize: '1.125rem',
        color: '#1e1e1e',
        fontWeight: 500
      },
      h4: {
        fontSize: '0.99rem',
        color: '#1e1e1e',
        fontWeight: 500
      },
      h3: {
        fontSize: '1.25rem',
        color: '#1e1e1e',
        fontWeight: 600
      },
      h2: {
        fontSize: '1.5rem',
        color: '#1e1e1e',
        fontWeight: 700
      },
      h1: {
        fontSize: '1.925rem',
        color: '#1e1e1e',
        fontWeight: 700
      },
      body: {
        fontSize: '1.10rem',
        color: '#1e1e1e',
        fontWeight: 300
      },
      subtitle: {
        fontSize: '0.85rem',
        color: '#1e1e1e',
        fontWeight: 300
      }
    },
    components: {
      MuiChip: {
        styleOverrides: {
          icon: {
            color: '#1e1e1e'
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            // color: '#1e1e1e',
            boxShadow: 'none',
            borderRadius: 10,
            fontSize: 16,
            padding:'10px'
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            color: '#1e1e1e',
            '&::placeholder': {
              color: '#959595',
              fontSize: '0.875rem'
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            background: '#fff',
            borderRadius: 4,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#dedede'
            },
            '&:hover $notchedOutline': {
              borderColor: '#43a047'
            },
            '&.MuiInputBase-multiline': {
              padding: 1
            }
          },
          input: {
            background: '#fff',
            padding: '15.5px 14px',
            borderRadius: 4,
            '&.MuiInputBase-inputSizeSmall': {
              padding: '10px 14px',
              '&.MuiInputBase-inputAdornedStart': {
                paddingLeft: 0
              }
            }
          },
          inputAdornedStart: {
            paddingLeft: 4,
            paddingRight: 4
          },
          notchedOutline: {
            borderRadius: 4
          }
        }
      },
    },
  });

  return (
    <ThemeContext.Provider value={Themes}>
      <ThemeProvider theme={Themes}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProviderContext };
