import React from 'react'
import Header from '../../layouts/Header'
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import Footer from '../../layouts/Footer'
function WorkShop() {
    return (
        <>
            <Header />
            <Box sx={{
                py: 5
            }}>
                <Container maxWidth='lg' >
                    <Grid container mt={2} alignItems='center'>
                        <Grid item xs={12}>
                        <Typography variant='h2'>Workshop fitoot</Typography>
                            <Stack spacing={1} mt={2}>
                                <Typography>• We are happy to accept returns for an exchange or credit but not refund</Typography>
                                <Typography>• Refund product must be in new confition and are subject to inspection</Typography>
                                <Typography>• For more information please contact, Retail@tddesign4x4.com.au</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Footer />
        </>
    )
}

export default WorkShop 