import { AppBar, Avatar, Box, Button, ButtonGroup, Container, Divider, Grid, IconButton, Link, Paper, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import instagram from '../assets/instagram.png'
import facebook from '../assets/facebook.png'
import line from '../assets/line-icon.png'
import youtube from '../assets/youtube.png'
import { facebook_link, instagram_link, line_link, youtube_link } from '../hostname';

function Footer() {
    const date = new Date();
    const navigate = useNavigate()

    return (
        <div style={{ backgroundImage: 'linear-gradient(to bottom left, #1A194D, #18294E)', paddingTop: 5, paddingBottom: 2, marginTop: 35 }}>
            <Container maxWidth='lg' sx={{ pt: 3, pb: 2 }} >
                <Grid container spacing={3}>
                    <Grid item mt={2} xs={12} sm={6} lg={3}>
                        <Stack spacing={1}>
                            <Typography fontWeight='bold' sx={{ color: '#ffffff',fontSize:'18px' }}>CONTACT US</Typography>
                            <Typography sx={{ color: '#ffffff' }}>Email:</Typography>
                            <Typography sx={{ color: '#ffffff' }}>retail@tddesign4x4.com.au</Typography>
                            <Typography sx={{ color: '#ffffff' }}>sale@tddesign4x4.com.au</Typography>
                            <Typography sx={{ color: '#ffffff' }}>todd@tddesign4x4.com.au</Typography>
                        </Stack>
                        <Stack spacing={1} mt={2}>
                            <Typography sx={{ color: '#ffffff' }} mt={1}>Phone:</Typography>
                            <Typography sx={{ color: '#ffffff' }}>049 - 2997064</Typography>
                        </Stack>
                        <Stack spacing={1} mt={2}>
                            <Typography sx={{ color: '#ffffff' }} mt={1}>Location:</Typography>
                            <Typography sx={{ color: '#ffffff' }}>7 Lowe street gloucester 2422 NSW</Typography>
                        </Stack>
                    </Grid>
                    <Grid item mt={2} xs={12} sm={6} lg={3}>
                        <Stack spacing={1}>
                            <Typography fontWeight='bold' sx={{ color: '#ffffff',fontSize:'18px'  }}>HELP CENTRE</Typography>
                            <Button variant='text' onClick={() => navigate('/return_policy')} sx={{ '&:hover': { backgroundColor: 'none' }, textTransform: 'capitalize', justifyContent: 'left', p: 0 }}><Typography sx={{ color: '#ffffff' }}>Return Policy</Typography></Button>
                            <Button variant='text' onClick={() => navigate('/delivery_information')} sx={{ '&:hover': { backgroundColor: 'none' }, textTransform: 'capitalize', justifyContent: 'left', p: 0 }}><Typography sx={{ color: '#ffffff' }}>Delivery Information</Typography></Button>
                            <Button variant='text' onClick={() => navigate('/privacy_terms')} sx={{ '&:hover': { backgroundColor: 'none' }, textTransform: 'capitalize', justifyContent: 'left', p: 0 }}><Typography sx={{ color: '#ffffff' }}>Privacy & Terms</Typography></Button>
                            <Button variant='text' onClick={() => navigate('/warranty_info')} sx={{ '&:hover': { backgroundColor: 'none' }, textTransform: 'capitalize', justifyContent: 'left', p: 0 }}><Typography sx={{ color: '#ffffff' }}>Warranty Info</Typography></Button>
                            <Button variant='text' onClick={() => navigate('/work_shop')} sx={{ '&:hover': { backgroundColor: 'none' }, textTransform: 'capitalize', justifyContent: 'left', p: 0 }}><Typography sx={{ color: '#ffffff' }}>Work shop / Fitout</Typography></Button>
                        </Stack>
                    </Grid>
                    <Grid item mt={2} xs={12} sm={6} lg={3}>
                        <Stack spacing={1}>
                            <Typography fontWeight='bold' sx={{ color: '#ffffff' ,fontSize:'18px' }}>CATEGORIES</Typography>
                            <Button variant='text' onClick={() => navigate('/trays')} sx={{ '&:hover': { backgroundColor: 'none' }, textTransform: 'capitalize', justifyContent: 'left', p: 0 }}><Typography sx={{ color: '#ffffff' }}>Trays</Typography></Button>
                            <Button variant='text' onClick={() => navigate('/canopies')} sx={{ '&:hover': { backgroundColor: 'none' }, textTransform: 'capitalize', justifyContent: 'left', p: 0 }}><Typography sx={{ color: '#ffffff' }}>Canopies</Typography></Button>
                            <Button variant='text' onClick={() => navigate('/performance')} sx={{ '&:hover': { backgroundColor: 'none' }, textTransform: 'capitalize', justifyContent: 'left', p: 0 }}><Typography sx={{ color: '#ffffff' }}>TD PERFORMANCE</Typography></Button>
                            <Button variant='text' onClick={() => navigate('/store')} sx={{ '&:hover': { backgroundColor: 'none' }, textTransform: 'capitalize', justifyContent: 'left', p: 0 }}><Typography sx={{ color: '#ffffff' }}>Other product</Typography></Button>
                        </Stack>
                    </Grid>
                    <Grid item mt={2} xs={12} sm={6} lg={3}>
                        <Stack spacing={1}>
                            <Typography fontWeight='bold' sx={{ color: '#ffffff',fontSize:'18px'  }}>Follow us</Typography>
                            <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                                <Tooltip title="facebook">
                                    <IconButton onClick={() => window.open(facebook_link)} sx={{ p: 1.5 }}>
                                        <Avatar alt="facebook" variant='square' src={facebook} sx={{ width: '35px', height: '35px' }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="instagram">
                                    <IconButton onClick={() => window.open(instagram_link)} sx={{ p: 1.5 }}>
                                        <Avatar alt="instagram" variant='square' src={instagram} sx={{ width: '35px', height: '35px' }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="youtube">
                                    <IconButton onClick={() => window.open(youtube_link)} sx={{ p: 1.5 }}>
                                        <Avatar alt="youtube" variant='square' src={youtube} sx={{ width: '35px', height: '35px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
            <Divider sx={{ backgroundColor: '#fff', mt: 5, mb: 2 }} />
            <Container maxWidth='lg'>
                <Box pb={1}>
                    <Typography fontSize='small' align='center' sx={{ color: '#ffffff' }}>© TDDESIGNS4x4 Ltd 2023. All Rights Reserved.</Typography>
                </Box>
            </Container>
        </div>
    )
}

export default Footer