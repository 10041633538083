import React from 'react'
import Header from '../../layouts/Header'
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import Footer from '../../layouts/Footer'
function WarrantyInfo() {
    return (
        <>
            <Header />
            <Box sx={{
                py: 5
            }}>
                <Container maxWidth='lg' >
                    <Grid container mt={2} alignItems='center'>
                        <Grid item xs={12}>
                            <Typography variant='h2'>Warranty Info</Typography>
                            <Stack spacing={1} mt={2}>
                                <Typography fontWeight='bold'>• Trays and canopies</Typography>
                                <Typography>For the TD Design 4x4 Trays and copies warrants for a period of 24 months from the date of the original purchase, the structural integrity of the body, light tail, under tray water tank, trundle drawer, under tray toolbox, or cracks from welding, except for an accident or caused by the customer's actions</Typography>
                                <Typography>We will repair or replace at this discretion, any defects during the warranty period</Typography>
                                <Typography>In the event that our Products must be returned to our facility for inspection, the owner must assume the cost and liability of Freight</Typography>
                            </Stack>
                            <Stack spacing={1} mt={2}>
                                <Typography fontWeight='bold'>• For Ms Product</Typography>
                                <Typography>All Ms product id lifetime warranty, But must be checked by the TD Design 4x4 Teams</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Footer />
        </>
    )
}

export default WarrantyInfo