import React from 'react'
import Header from '../../layouts/Header'
import { Box, Container, Grid, Typography } from '@mui/material'
import Footer from '../../layouts/Footer'
function PrivacyTerms() {
    return (
        <div style={{fontFamily:'line_seed_sans_th'}}>
            <Header />
            <Box sx={{
                py: 5
            }}>
                <Container maxWidth='lg' >
                    <Grid container mt={2} alignItems='center'>
                        <Grid item xs={12}>
                            <h1 style={{fontFamily:'line_seed_sans_th'}}>Terms and conditions</h1>
                            <p>These terms and conditions (&#8220;Agreement&#8221;) set forth the general terms and conditions of your use of the <a href="https://www.TDDESIGN4X4.com.au">TDDESIGN4X4.com.au</a> website (&#8220;Website&#8221; or &#8220;Service&#8221;) and any of its related products and services (collectively, &#8220;Services&#8221;). This Agreement is legally binding between you (&#8220;User&#8221;, &#8220;you&#8221; or &#8220;your&#8221;) and TD DESIGN 4X4 Pty. Ltd. (&#8220;TD DESIGN 4X4 Pty. Ltd.&#8221;, &#8220;we&#8221;, &#8220;us&#8221; or &#8220;our&#8221;). If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms &#8220;User&#8221;, &#8220;you&#8221; or &#8220;your&#8221; shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Website and Services. By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. You acknowledge that this Agreement is a contract between you and TD DESIGN 4X4 Pty. Ltd., even though it is electronic and is not physically signed by you, and it governs your use of the Website and Services.</p>
                            <div class="wpembed-toc"><h3>Table of contents</h3><ol class="wpembed-toc"><li><a href="#accounts-and-membership">Accounts and membership</a></li><li><a href="#user-content">User content</a></li><li><a href="#adult-content">Adult content</a></li><li><a href="#backups">Backups</a></li><li><a href="#links-to-other-resources">Links to other resources</a></li><li><a href="#intellectual-property-rights">Intellectual property rights</a></li><li><a href="#changes-and-amendments">Changes and amendments</a></li><li><a href="#acceptance-of-these-terms">Acceptance of these terms</a></li><li><a href="#contacting-us">Contacting us</a></li></ol></div><h2 id="accounts-and-membership">Accounts and membership</h2>
                            <p>If you create an account on the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions.</p>
                            <h2 id="user-content">User content</h2>
                            <p>We do not own any data, information or material (collectively, &#8220;Content&#8221;) that you submit on the Website in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. We may monitor and review the Content on the Website submitted or created using our Services by you. You grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you. Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable. You also grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose.</p>
                            <h2 id="adult-content">Adult content</h2>
                            <p>Please be aware that there may be certain adult or mature content available on the Website. Where there is mature or adult content, individuals who are less than 18 years of age or are not permitted to access such content under the laws of any applicable jurisdiction may not access such content. If we learn that anyone under the age of 18 seeks to conduct a transaction through the Services, we will require verified parental consent, in accordance with the Children&#8217;s Online Privacy Protection Act of 1998 (&#8220;COPPA&#8221;). Certain areas of the Website and Services may not be available to children under 18 under any circumstances.</p>
                            <h2 id="backups">Backups</h2>
                            <p>We perform regular backups of the Website and its Content and will do our best to ensure completeness and accuracy of these backups. In the event of the hardware failure or data loss we will restore backups automatically to minimize the impact and downtime.</p>
                            <h2 id="links-to-other-resources">Links to other resources</h2>
                            <p>Although the Website and Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link on the Website. Your linking to any other off-site resources is at your own risk.</p>
                            <h2 id="intellectual-property-rights">Intellectual property rights</h2>
                            <p>&#8220;Intellectual Property Rights&#8221; means all present and future rights conferred by statute, common law or equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, rights to claim priority from, such rights and all similar or equivalent rights or forms of protection and any other results of intellectual activity which subsist or will subsist now or in the future in any part of the world. This Agreement does not transfer to you any intellectual property owned by TD DESIGN 4X4 Pty. Ltd. or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with TD DESIGN 4X4 Pty. Ltd. All trademarks, service marks, graphics and logos used in connection with the Website and Services, are trademarks or registered trademarks of TD DESIGN 4X4 Pty. Ltd. or its licensors. Other trademarks, service marks, graphics and logos used in connection with the Website and Services may be the trademarks of other third parties. Your use of the Website and Services grants you no right or license to reproduce or otherwise use any of TD DESIGN 4X4 Pty. Ltd. or third party trademarks.</p>
                            <h2 id="changes-and-amendments">Changes and amendments</h2>
                            <p>We reserve the right to modify this Agreement or its terms related to the Website and Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.</p>
                            <p>An updated version of this Agreement will be effective immediately upon the posting of the revised Agreement unless otherwise specified. Your continued use of the Website and Services after the effective date of the revised Agreement (or such other act specified at that time) will constitute your consent to those changes.</p>
                            <h2 id="acceptance-of-these-terms">Acceptance of these terms</h2>
                            <p>You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Website and Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Website and Services. This terms and conditions policy was created with the help of <a href="https://www.websitepolicies.com/terms-and-conditions-generator" target="_blank">WebsitePolicies</a>.</p>
                            <h2 id="contacting-us">Contacting us</h2>
                            <p>If you have any questions, concerns, or complaints regarding this Agreement, we encourage you to contact us using the details below:</p>
                            <p><a href="https://www.tddesign4x4.com.au">https://www.tddesign4x4.com.au</a><br /><a href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;to&#100;&#100;&#64;&#116;d&#100;&#101;sig&#110;&#52;x4&#46;&#99;&#111;m.&#97;u">t&#111;dd&#64;&#116;&#100;de&#115;ig&#110;4x&#52;.&#99;&#111;m.&#97;&#117;</a></p>
                            <p>This document was last updated on May 30, 2023</p>
                            <p class="madewith"><a href="https://www.websitepolicies.com/terms-and-conditions-generator?via=madewithbadge" target="_blank"><img width="200" height="25" alt="Made with WebsitePolicies terms and conditions generator" src="https://cdn.websitepolicies.io/img/badge.png" srcset="https://cdn.websitepolicies.io/img/badge_2x.png 2x" /></a></p>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Footer />
        </div>
    )
}

export default PrivacyTerms