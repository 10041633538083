import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import logo from '../assets/logo.jpg'
import shopping from '../assets/shopping-cart.png'
import user from '../assets/user.png'
import { Badge, Button, ButtonGroup, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { tdContext } from '../context/Context';
import { useNavigate } from 'react-router-dom';
import { styled } from "@mui/material/styles";

function Header() {
  const queryString = window.location.pathname
  let path = queryString.split('/')[1]
  const navigate = useNavigate()
  const { location, quantityAmount } = React.useContext(tdContext)
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const getColor = (paths) => {
    if (path === paths) {
      return '#3f51b5'
    } else {
      return '#000000'
    }
  }
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setAnchorElNav(open);
  };
  return (
    <>
      <Box sx={{ p: 1, py: 0.5, backgroundColor: '#e8eaf6' }}>
        <Container maxWidth="lg">
          <Stack direction='row' justifyContent='flex-end' spacing={1}>
            <Link underline='none' onClick={() => navigate('/faq')}><Typography fontSize='small'>help</Typography></Link>
            <Typography fontSize='small' color='GrayText' >|</Typography>
            <Typography fontSize='small' onClick={() => navigate('/about')}>about us</Typography>
          </Stack>
        </Container>
      </Box>
      <AppBar position='sticky' top={0} color='neutral' elevation={0} sx={{ borderBottom: 'solid 1px #dedede' }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Box sx={{ display: { xs: 'flex', md: 'none' }, flexDirection: 'column', width: '100%' }}>
              <Grid container alignItems='center'>
                <Grid item xs={2}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={8}>
                  <Button fullWidth variant='text' onClick={() => navigate('/')} sx={{ '&:hover': { backgroundColor: '#fff' }, justifyContent: 'center', p: 0 }} startIcon={<img src={logo}  style={{ height: '30px', width: 'auto' }} />}>
                      <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        onClick={() => navigate('/')}
                        sx={{
                          fontWeight: 700,
                          letterSpacing: '.2rem',
                          color: 'inherit',
                          textDecoration: 'none',
                        }}
                      >
                        TDDESIGNS4x4
                      </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
              <Avatar src={logo} variant='square' sx={{ height: '30px', width: '52px', display: { xs: 'none', md: 'flex' }, mr: 1 }} />
              <Button variant='text' onClick={() => navigate('/')} sx={{ '&:hover': { backgroundColor: '#fff' }, justifyContent: 'left', p: 0 }}>
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontWeight: 700,
                    letterSpacing: '.2rem',
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  TDDESIGNS4x4
                </Typography>
              </Button>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Stack direction='row' spacing={1}>
                <Button variant='text' onClick={() => navigate('/trays')} sx={{ p: 1, color: getColor('trays'), '&:hover': { backgroundColor: '#fff' } }}><Typography fontWeight='bold'>TRAYS</Typography></Button>
                <Button variant='text' onClick={() => navigate('/canopies')} sx={{ p: 1, color: getColor('canopies'), '&:hover': { backgroundColor: '#fff' } }}><Typography fontWeight='bold'>CANOPIES</Typography></Button>
                <Button variant='text' onClick={() => navigate('/performance')} sx={{ p: 1, color: getColor('performance'), '&:hover': { backgroundColor: '#fff' } }}><Typography fontWeight='bold'>TD PERFORMANCE</Typography></Button>
                <Button variant='text' onClick={() => navigate('/store')} sx={{ p: 1, color: getColor('store'), '&:hover': { backgroundColor: '#fff' } }}><Typography fontWeight='bold'>STORE</Typography></Button>
              </Stack>
            </Box>

            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
              <Tooltip title="account">
                <IconButton onClick={() => navigate('/account')} sx={{ p: 1.5 }}>
                  <Avatar alt="account" variant='square' src={user} sx={{ width: '20px', height: '20px' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="shopping cart">
                <IconButton onClick={() => navigate('/shopping_cart')} sx={{ p: 1.5 }}>
                  <Badge badgeContent={quantityAmount || 0} color="error">
                    <Avatar alt="shopping cart" variant='square' src={shopping} sx={{ width: '20px', height: '20px' }} />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor='left'
        open={Boolean(anchorElNav)}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem key={'TDDESIGN4x4'} disablePadding>
              <ListItemButton onClick={() => navigate('/')}><ListItemText primary={'TDDESIGN4x4'} /></ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem key={'trays'} disablePadding>
              <ListItemButton onClick={() => navigate('/trays')}><ListItemText primary={'Trays'} /></ListItemButton>
            </ListItem>
            <ListItem key={'canopies'} disablePadding>
              <ListItemButton onClick={() => navigate('/canopies')}><ListItemText primary={'Canopies'} /></ListItemButton>
            </ListItem>
            <ListItem key={'performance'} disablePadding>
              <ListItemButton onClick={() => navigate('/performance')}><ListItemText primary={'Performance'} /></ListItemButton>
            </ListItem>
            <ListItem key={'store'} disablePadding>
              <ListItemButton onClick={() => navigate('/store')}><ListItemText primary={'Store'} /></ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem key={'shopping_cart'} disablePadding>
              <ListItemButton onClick={() => navigate('/shopping_cart')}><ListItemText primary={<Badge badgeContent={quantityAmount || 0} color="error"><Typography>Shopping cart </Typography></Badge>} /></ListItemButton>
            </ListItem>
            <ListItem key={'account'} disablePadding>
              <ListItemButton onClick={() => navigate('/account')}><ListItemText primary={'Account'} /></ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      {/* <Toolbar /> */}
    </>
  );
}
export default Header;