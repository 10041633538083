import React from 'react'
import { Alert, Snackbar } from '@mui/material'

function Snacks(props) {
  const { open, msg, variant } = props

  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={() => props.onClose(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={() => props.onClose(false)} severity={variant} variant="filled" sx={{ width: '100%' }}>
        {msg}
      </Alert>
    </Snackbar>
  )
}

export default Snacks